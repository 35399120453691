import React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'

import { productPath } from 'routes'
import { Cards, CategoryCard } from '@accodeing/gatsby-theme-switsbake'
import Layout from 'components/Layout'

const productImage = (images, productId) => {
  const image = images.filter((image) => image.name === productId)[0]
  if (!image) {
    console.error(
      `Can't find image for ${productId} among these images:`,
      images
    )
  }
  return image
}

const Products = ({ images, products, slug }) =>
  products.map((product) => {
    const image = productImage(images, product.articleId).childImageSharp
      .gatsbyImageData

    return (
      <CategoryCard
        title={product.articleName}
        linkTo={productPath(product.trademarkId, product.articleId)}
        key={product.articleId}
      >
        <GatsbyImage
          alt={product.articleName}
          image={image}
          imgStyle={{
            objectFit: 'contain',
          }}
        />
      </CategoryCard>
    )
  })

const FoodserviceTrademarkPage = ({ pageContext, data }) => {
  const crumbs = pageContext.breadcrumb.crumbs.slice(1)
  const { products, title, slug } = pageContext

  return (
    <Layout
      pageTitle={title}
      backgroundImage={data.imageBackground.childImageSharp.gatsbyImageData}
    >
      <oma-grid-row class='row row--with-bottom-margin'>
        <section className='section section--with-background'>
          <h1 className='page__heading'>{title}</h1>
          <Breadcrumb crumbs={crumbs} crumbLabel={title} />
        </section>
        <section className='section'>
          <Cards>
            <Products
              products={products}
              images={data.images.nodes}
              slug={slug}
            />
          </Cards>
        </section>
      </oma-grid-row>
    </Layout>
  )
}

export const query = graphql`
  query ($trademarkImageDirectory: String!) {
    imageBackground: file(relativePath: { eq: "cakes_bg2.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 60, layout: FULL_WIDTH)
      }
    }
    images: allFile(
      filter: { relativeDirectory: { eq: $trademarkImageDirectory } }
    ) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(width: 520, quality: 60, layout: CONSTRAINED)
        }
      }
    }
  }
`

export default FoodserviceTrademarkPage
